<style>
.negrilla {
    color: #363636 !important;
}
</style>
<template>
    <card class="custom-container">
        <!-- <div>
            <input type="file" @change="handleFileUpload" accept=".xlsx, .xls" />

            <b-form-select id="empresa" required v-model="tableName" value-field="valor" text-field="nombre"
                :options="tablaSel">
                <template #first>
                    <b-form-select-option :value="null" disabled>
                        -- Selecciona una opcion --
                    </b-form-select-option>
                </template>
</b-form-select>

{{ tableName }}
{{ file }}

<button @click="uploadFile" class=" bg-success" :disabled="!file || !tableName">
    Subir archivo
</button>
<div v-if="error" class=" bg-danger">
    {{ error }}
</div>
</div> -->

        <div class="row p-4">
            <div class="col-12 col-lg-6 mx-auto">
                <span class="h2">Cargar Honorarios</span>
                <div class="d-flex flex-column align-items-center mt-3" style="gap: 1rem">
                    <!-- drop file -->
                    <DropFileBasic @handleFileData="handleFileData" :loader="loader" :fileTypes="[
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ]" />

                    <div class="w-100">
                        <label for="empresa" class="form-label">Seleccionar tabla honorarios</label>
                        <b-form-select id="empresa" required v-model="tableName" value-field="valorExactoTabla" text-field="nombreTabla"
                            :options="tablaSel">
                            
                            <template #first>
                                <b-form-select-option :value="null" disabled>
                                    -- Selecciona una opcion --
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <vs-button @click="uploadFile" :disabled="!file || !tableName"> Cargar Honorarios </vs-button>
                </div>

                <div class="d-flex row align-items-start mt-4" style="gap: 1rem">
                    <h2 class=" col-10">Descargar plantillas</h2>

                    <vs-tooltip>
                        <vs-button style="width: 30px; height: 28px;" circle icon relief
                            @click="activeModal = !activeModal">
                            <i class="fa-solid fa-exclamation"></i>
                        </vs-button>
                        <template #tooltip>
                            Más Información
                        </template>
                    </vs-tooltip>

                    <vs-dialog width="550px" not-center v-model="activeModal">
                        <template #header>
                            <h2 class="not-margin ">
                                Plantillas <b class="negrilla">Honorarios</b>
                            </h2>
                        </template>


                        <div class="con-content negrilla">
                            <p class="negrilla">
                                En este apartado podrás descargar cada una de las plantillas necesarias para subir los
                                honorarios a la plataforma.
                            </p>
                            <p class="negrilla">
                                Es importante que se respeten los formatos establecidos para garantizar el correcto
                                funcionamiento del sistema.
                            </p>
                            <ul>
                                <li>No modifiques los encabezados ni los elimines.</li>
                                <li>El campo <strong>ID</strong> debe permanecer vacío.</li>
                            </ul>
                            <p class="negrilla">
                                Por favor, asegúrate de seguir estas indicaciones antes de cargar los archivos.
                            </p>
                        </div>

                        <template #footer>
                            <div class=" d-flex">
                                <vs-button @click="activeModal = false" transparent>
                                    Ok
                                </vs-button>
                                <vs-button @click="activeModal = false" dark transparent>
                                    Cancel
                                </vs-button>
                            </div>
                        </template>
                    </vs-dialog>

                    <div class=" col-12 d-flex row justify-content-center" v-for="(item, subIndex) in tablaSel" :key="subIndex">

                        <vs-button class=" col-6" success flat :active="active == subIndex"
                            @click="active = subIndex, getPlantilla(item.nombreTabla)">
                            <i class="fa-solid fa-download"></i> ‎ {{item.nombreTabla}}
                        </vs-button>
                    </div>

                </div>
            </div>
        </div>
    </card>




</template>

<script>
import Swal from 'sweetalert2';
import DropFileBasic from "@/components/helexium/DropFileBasic.vue";
export default {
    components: {
        DropFileBasic
    },
    data() {
        return {
            tablaSel: [],
            tableName: null,
            file: null,
            loader: false,
            error: null,
            activeModal: false,
            active: false
        };
    },
    async mounted() {

        await this.getTablesHonorarios();

    },
    methods: {
        async getTablesHonorarios() {
            try {
                // Hacemos la solicitud para obtener las tablas
                const response = await this.$store.dispatch("hl_get", {
                    path: "TableHonorariosDentiSalud/GetAllTablesDentisaludHonorarios",
                })
                console.log("Respuesta"+response)
                this.tablaSel = response
                return response
            }
            catch (error) {
                console.error("Error al obtener las tablas:", error);
            }
        },

        async getPlantilla(name) {
            try {
                // Hacemos la solicitud para obtener el archivo
                const response = await this.$store.dispatch("hl_get_file", {
                    path: "UploadHonorarios/plantillas/" + name,
                    responseType: "blob"  // Indicamos que la respuesta será un archivo
                });

                // Si la respuesta es correcta
                const blob = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // Tipo de archivo Excel
                const link = document.createElement('a');

                // Creamos una URL para el archivo y la asignamos al enlace
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute('download', name); // Nombre del archivo que se descargará
                document.body.appendChild(link);

                // Simulamos el click para forzar la descarga
                link.click();

                // Limpieza de URL y enlace
                link.remove();
                window.URL.revokeObjectURL(url);

                return response;
            } catch (error) {
                console.error("Error al descargar la plantilla:", error);
            }
        },


        handleFileData(event) {
            this.file = event;
            this.error = null;
        },

        async uploadFile() {
            if (!this.file || !this.tableName) return;


            this.error = null;
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('tableName', this.tableName);

            try {

                Swal.fire({
                    title: 'Procesando archivo',
                    html: 'Por favor espere mientras se procesa el archivo...<br/>' +
                        '<small class="text-muted">Este proceso puede tomar varios minutos dependiendo del tamaño del archivo.</small><div class=" d-flex justify-content-start row pt-3"><h4 class=" col-12"><b style="color: rgb(49, 114, 255) !important">Tiempos de carga (Aprox)</b></h4><br><p><b>Material Especial:</b> 10 Mins(max)</p><p><b>Items Detalle Liquidación:</b> 10 Mins(max)</p><p><b>Prestaciones:</b> 20 Mins(max)</p></div>',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                const response = await this.$store.dispatch("hl_post_file", {
                    path: "UploadHonorarios/?tableName=" + this.tableName,
                    data: formData
                });
                await Swal.close();

                // Mostrar alerta de éxito
                if (response.status === 200) {
                    await Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'El archivo se ha procesado correctamente.',
                        confirmButtonText: 'Aceptar'
                    });

                }

                else {

                    await Swal.close();
                    // Acá obtenemos el BadRequest Generado desde el Backend
                    const errorMessage = await response.text();
                    console.log("Respuesta del servidor:", errorMessage);

                    // Mostrar la alerta de error
                    await Swal.fire({
                        icon: 'error',
                        title: '¡Error al subir el archivo!',
                        text: 'Mensaje de error: ' + errorMessage + '\nPor favor revisa el contenido del archivo o contacta con soporte' || '\nError al procesar el archivo, Por favor revisa el contenido del archivo o contacta con soporte',
                        confirmButtonText: 'Aceptar'
                    });

                    this.file = '';
                    this.tableName = '';
                }



                //console.log("Respuesta del servidor:", response);

                this.$emit('upload-success');
                this.file = null;
                this.tableName = '';


            } catch (error) {


                await Swal.close();


            }
            //window.location.reload();
        }
    }
};
</script>
